/* Styles for the scroll snapping container */
 html {
  height: 100%;
  margin: 0;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

p {
  font-weight: bold;
}

#overview h1 {
  color: #FFF;
  text-shadow: 2px 2px 2px #000, -1px -1px 0px #000;
}

#overview p {
  color: #33c280;
}

#why h1 {
  color: yellow;
  text-shadow: 2px 2px 2px #000, -1px -1px 0px #000;
}

#why p {
  color: #ececc9;
}

#service h1 {
  color: #FFF;
  text-shadow: 2px 2px 2px #000, -1px -1px 0px #000;
}

#service p {
  color: black;
  background-color: white;
}

/* Styles for each section to align them for snapping */
.section {
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Basic styling for the navigation menu */
.navigation {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-image: url('assets/images/nav-background.png');
  background-size: cover; /* Cover the entire div without stretching the image */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.navigation button {
  margin: 0 10px;
  width: 100px;
  padding: 5px 10px;
  cursor: pointer;
}

.navigation button.active {
  font-weight: bold;
}

/* Placeholder styling for image area */
.image-placeholder {
  width: 300px;
  height: 200px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* 
.section {
  height: 100vh;
  scroll-snap-align: start;
}

.navigation {
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation button.active {
  font-weight: bold;
}

body {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
} */

